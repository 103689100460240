// A reference to Stripe.js initialized with a fake API key.
const stripe = window.Stripe("pk_test_51JVGfyCpnsatSeoyf5ggxh2DKCytSBedTM7Ft9zglU3U4VwipTXnQHYiT0pegHIEV8j1zLenLv4IoE6EVZ8rD7U500szQIX4S0");

// The items the customer wants to buy
const items = [{ id: "xl-tshirt" }];

let elements;
checkStatus();




// Fetches a payment intent and captures the client secret
export const fetchPaymentIntent = async () => {
    let status = {};

    status = await fetch("http://localhost:5001/flikbox-2606/us-central1/paymentIntent", {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(items)
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        const clientSecret = data["clientSecret"];
        const appearance = {
            theme: 'stripe',
            variables: {
                colorText: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            },
        };
        elements = stripe.elements({ appearance, clientSecret });

        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");

        return elements;
    });

    return status;
}


// Submit payment info
export const submitPaymentInfo = async () => {
    const error = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:8080/checkout",
        },
    });
    
    if (error.type === "card_error" || error.type === "validation_error") {
        console.log(error.message);
    } else {
        console.log("An unexpected error occured.", error);
    }
}


// Fetches the payment intent status after payment submission
async function checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
  
    if (!clientSecret) {
      return;
    }
  
    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
  
    switch (paymentIntent.status) {
      case "succeeded":
        console.log("Payment succeeded!");
        break;
      case "processing":
        console.log("Your payment is processing.");
        break;
      case "requires_payment_method":
        console.log("Your payment was not successful, please try again.");
        break;
      default:
        console.log("Something went wrong.");
        break;
    }
  }


/*


// ------- UI helpers -------

function showMessage(messageText) {
  const messageContainer = document.querySelector("#payment-message");

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

  setTimeout(function () {
    messageContainer.classList.add("hidden");
    messageText.textContent = "";
  }, 4000);
}

// Show a spinner on payment submission
function setLoading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    document.querySelector("#submit").disabled = true;
    document.querySelector("#spinner").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
  } else {
    document.querySelector("#submit").disabled = false;
    document.querySelector("#spinner").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }
}*/